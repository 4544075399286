import React from "react";

//Customizable Area Start

import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native";
import Arrowleft from "react-native-vector-icons/Feather";
import CustomInput from "../../../components/src/CommonInput";
import CustomLoader from "../../../components/src/CustomLoader";
import {
  verticalScale,
  heightPercentageToDP,
  widthPercentageToDP,
} from "../../../components/src/Scale";
import ForgotPasswordController, { Props, configJSON } from "./ForgotPasswordController";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <SafeAreaView style={styles.mainContainer}>
              <StatusBar barStyle="dark-content" backgroundColor={this.state.modalVisible?"rgba(0,0,0,0.2)":"#ffffff"} />
              <View style={styles.headerContainer}>
                <TouchableOpacity
                  style={styles.backButton}
                  testID="goBackButton"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                >
                  <Arrowleft
                    name="arrow-left"
                    size={verticalScale(28)}
                    color={"#4166f8"}
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.otpTitle}>Forgot Password</Text>
              <Text style={styles.otpDes}>
                Enter your registered email/mobile number below to receive password reset
                instructions.
              </Text>
              <View style={styles.inputContainer}>
                <CustomInput
                testID="emailorMobileInput"
                  placeHolder={"Email or Mobile"}
                  value={this.state.emailOrMobilenumber}
                  handleInput={this.handleEmailOrMobilenumber}
                  errorMsg={!this.state.isValidEmailOrMobile&& configJSON.emailMobileText}
                  valid = {this.state.isValidEmailOrMobile}
                />
            <CustomLoader modalVisible = {this.state.modalVisible} modalClose = {this.handleModalClose}/>
                <TouchableOpacity
                testID="sendOtpButton"
                  style={[
                    styles.sendOtpButton,
                    {
                      backgroundColor: this.state.isValidEmailOrMobile
                        ? "#4166f8"
                        : "#c2cfe1",
                    },
                  ]}
                  disabled={this.state.isValidEmailOrMobile ? false : true}
                  onPress={() => this.handleSendOtp()}
                >
                  <Text style={styles.sendOtpButtonText}>Send OTP</Text>
                </TouchableOpacity>
              </View>
            </SafeAreaView>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center",
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1,
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%",
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1,
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1,
  },

  mobileInput: {
    flexDirection: "column",
    alignItems: "stretch",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
  },

  codeInput: {
    marginTop: 20,
    width: "30%",
  },

  phoneInput: {
    flex: 3,
    marginTop: 20,
  },

  noBorder: {
    borderBottomWidth: 0,
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold",
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10,
  },

  bgRectWeb: {
    marginTop: 40,
  },

  errorStyle: {
    color: "red",
    textAlign: "center",
  },
  container: {
    flex: 1,
    padding: 16,
    width:  "100%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },

  phoneInputMobile: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginBottom: 64,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1,
  },

  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    height: heightPercentageToDP(8),
    width: widthPercentageToDP(90),
    justifyContent: "flex-start",
    alignSelf: "center",
  },
  backButton: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(12.5),
    borderRadius: verticalScale(25),
    backgroundColor: "#eceef1",
    justifyContent: "center",
    alignItems: "center",
  },
  otpTitle: {
    fontSize: verticalScale(33),
    color: "#212330",
    fontWeight: "400",
    marginTop: verticalScale(25),
  },
  otpDes: {
    fontSize: verticalScale(14),
    color: "#3c4557",
    marginTop: verticalScale(20),
    fontWeight: "400",
    textAlign: "center",
  },
  inputContainer: {
    marginTop: verticalScale(100),
  },
  sendOtpButton: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(90),

    marginTop: verticalScale(50),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: verticalScale(15),
    alignSelf: "center",
    marginBottom: verticalScale(30),
  },
  sendOtpButtonText: {
    fontSize: verticalScale(18),
    color: "#fff",
    fontWeight: "400",
    alignSelf: "center",
  },
});
