import React, { Component } from 'react';
import { View, TextInput, StyleSheet, Text, KeyboardTypeOptions } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { configJSON } from '../../blocks/email-account-registration/src/EmailAccountRegistrationController';
import { verticalScale, widthPercentageToDP, heightPercentageToDP } from "./Scale";

interface IProps {
    handleInput: (value: string) => void;
    placeHolder?: string;
    value?: string;
    icon?: boolean;
    showHidePassword?: () => void;
    iconName?: string;
    errorMsg?: string;
    passwordVisibility?: boolean;
    keyboardType?: KeyboardTypeOptions | undefined;
    testID?:string;
    valid?: boolean;



}
class CustomInput extends Component<IProps>{
    constructor(props: IProps) {
        super(props);
    }
    handleInput = (value: string) => {
        this.props.handleInput(value);
    }
    showHidePassword = () => {
        this.props.showHidePassword && this.props.showHidePassword()
    }
    render() {


        return (
            <View>
                <View style={[styles.inputContainer, { borderColor: String(this.props.value).length > 0 ? this.props.valid && String(this.props.value).length > 0 ? 'black' : '#ff0000' : "black", borderWidth: String(this.props.value).length > 0 ? this.props.valid && String(this.props.value).length > 0 ? 0 : 1 : 0 }]}>
                    <TextInput
                        placeholder={this.props.placeHolder}
                        autoCapitalize='none'
                        style={styles.passwordInput}
                        onChangeText={(t: string) => this.handleInput(t)}
                        value={this.props.value}
                        secureTextEntry={this.props.passwordVisibility}
                        keyboardType={this.props.keyboardType ? this.props.keyboardType : "default"}
                    />
                    {
                        this?.props?.icon ?
                            <>
                                <View style={styles.iconContainer}>
                                    <Icon name={this.props.iconName ? this.props.iconName : "eye-off"}
                                        size={verticalScale(20)}
                                        color={"#b1b6cd"}
                                        onPress={() => this.showHidePassword()} />
                                </View>
                            </> :
                            <></>
                    }
                </View>
                {
                    this.props.value && this.props.value.length > 0 && this.props.errorMsg?.length && this.props.errorMsg?.length > 0 ?
                        <Text style={styles.errorMsg}>{this.props.errorMsg}</Text> :
                        <></>
                }
            </View>
        )
    }

}
export default CustomInput;

const styles = StyleSheet.create({
    inputContainer: {
        backgroundColor: '#F4F6FB',
        height: heightPercentageToDP(6.5),
        width: widthPercentageToDP(90),
        justifyContent: 'space-between',
        marginTop: verticalScale(10),
        borderRadius: verticalScale(10),
        flexDirection: "row",
        alignItems:'center',
        padding:verticalScale(5)
        
    },
    passwordInput: {
        fontSize: verticalScale(14),
        color: "#000000",
        fontWeight: "400",
        height: heightPercentageToDP(5.8),
        width: widthPercentageToDP(75),
        borderRadius: verticalScale(15),
        backgroundColor: '#F4F6FB',
        paddingLeft: verticalScale(20),
    },
    iconContainer: {
        height:heightPercentageToDP(5.8),
        width: widthPercentageToDP(15),
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMsg: {
        paddingLeft: verticalScale(5),
        fontSize: verticalScale(10),
        color: "#bbbbbb",
        fontWeight: "400",
        marginTop: verticalScale(5)
    }


}) 