import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {imgPasswordVisible , imgPasswordInVisible } from "./assets"
import {Alert} from 'react-native'

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  accountStatus: string;
  passwordRules: string;
  emailValue: string;
  phoneValue: string;
  countryCodeSelected: string;
  token: string;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  emailOrMobilenumber: string;
  isValidEmailOrMobile: boolean;
  password: string;
  newConfirmPassword: string;
  showHidePassword: boolean;
  newShowHideConfirmPassword: boolean;
  isValidPassword: boolean;
  isValidNewConfirmPassword: boolean;
  otpPin: string;
  forgotPasswordToken: string;
  otpAuthToken: string;
  isEmail:boolean;
  modalVisible: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: string = "";
  requestEmailOtpCallId: string = "";
  requestPhoneOtpCallId: string = "";
  requestChangePasswordCallId: string = "";
  requestGoToConfirmationCallId: string = "";
  otpToken: string = "";
  isChangePassword: boolean = false;
  getOtpAndTokenCallId: string = "";
  setNewPasswordApiCallId: string = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: string = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: string = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: string = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: string = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: string = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: string = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: string = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: string = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: string = imgPasswordInVisible;
  imgPasswordVisible: string = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: string = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      emailOrMobilenumber: "",
      isValidEmailOrMobile: false,
      password: "",
      newConfirmPassword: "",
      showHidePassword: true,
      newShowHideConfirmPassword: true,
      isValidPassword: false,
      isValidNewConfirmPassword: false,
      otpPin: "",
      forgotPasswordToken: "",
      otpAuthToken: "",
      isEmail:false,
      modalVisible:false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          // getUserTypesListApiCallId
          if (apiRequestCallId && responseJson) {
            this.getOtpTokenResponse(apiRequestCallId, responseJson)
           this.setNewPasswordApiResponse(apiRequestCallId, responseJson)
          }
        }
        // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : ""
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : ""
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : ""
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  // Customizable Area Start

  getOtpTokenResponse = (apiRequestCallId:string,responseJson:{data:{attributes:{pin:string}},meta:{token:string},errors:[{otp:string}]} ) =>{
    if (apiRequestCallId === this.getOtpAndTokenCallId) {
      if (responseJson.data) {
        setTimeout(() =>{
          this.showAlert(configJSON.successTitle, configJSON.otpSentMsg);
        },10)
        this.setState(
          {
            forgotPasswordToken: responseJson.meta.token,
            modalVisible:false
          },
          () => {
            this.props.navigation.navigate(
              "ForgotPasswordOTPVerification",
              {
                emailOrMobileNumber: this.state.emailOrMobilenumber,
                token: this.state.forgotPasswordToken,
                isEmail:this.state.isEmail
              }
            );
            
          }
        );
      } else {
        this.setState({modalVisible:false}, () =>{
          setTimeout(() =>{
            this.showAlert(configJSON.errorTitle, responseJson?.errors[0]?.otp);
          },10)
        })
      }
    }
  }
  setNewPasswordApiResponse = (apiRequestCallId: string, responseJson: {
    serialized_account: {
      data: {
        id: string,
        type: string,
        attributes: {
          "activated": boolean,
          "country_code": string,
          "email": string,
          "first_name": string,
          "full_phone_number": string,
          "last_name": string,
          "phone_number": string,
          "type": string,
          "ssn": string,
          "created_at": string,
          "updated_at": string,
          "device_id": null,
          "unique_auth_id": string
        }
      }
    },
    "message": string,
    "errors": [
      {
        "token": string,
        "otp": string
      }
    ]
  }) => {
    if (apiRequestCallId === this.setNewPasswordApiCallId) {

      if (responseJson.message) {
        this.setState({ modalVisible: false })
        setTimeout(() =>{
          Alert.alert(
            configJSON.successTitle,
            responseJson?.message,
            [
  
              { text: 'OK', onPress: () => this.props.navigation.navigate("EmailAccountLoginBlock") },
            ]
          );

        },10)
        
      } else {
        this.setState({ modalVisible: false }, () => {
          setTimeout(() =>{
            this.showAlert(configJSON.errorTitle, responseJson?.errors[0]?.otp)
          },10)
        })
      }
    }
  }
  handleEmailOrMobilenumber = (value: string) => {
    this.setState(
      {
        emailOrMobilenumber: value,
        isValidEmailOrMobile: this.validateEmail(value),
      }
    );
  };
  validateEmail = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^(?:\+91|0)?[789]\d{9}$/;
    const usaRegex = /^\d{10}$/;
    if (emailRegex.test(value) ) {
      this.setState({ isEmail: true });
      return true;
    } else if(mobileRegex.test(value) || usaRegex.test(value) ) {
      this.setState({isEmail: false});
      return true;
    }
    else {
      return false;
    }
  };
  getOtpAndToken = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data1 = {
      type: configJSON.mobileAccountType,
      attributes: {
        full_phone_number:this.state.emailOrMobilenumber.startsWith("+91") ? this.state.emailOrMobilenumber : `1${this.state.emailOrMobilenumber}`,
      },
    };
    const data2 = {
      type: configJSON.emailAccountType,
      attributes: {
        email: this.state.emailOrMobilenumber,
      },
    };
    const httpBody = {
      data: this.state.isEmail? data2: data1,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOtpAndTokenCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSendOtp = () => {
    this.setState({modalVisible: true}, () =>{
      if (this.state.emailOrMobilenumber && this.state.isValidEmailOrMobile) {
        this.getOtpAndToken();
      } else {
        setTimeout(() =>{
          this.showAlert(configJSON.errorTitle, configJSON.errorEmailValidationMsg);
        },10)
      }
    })
  };
  handlePassword = (value: string) => {
    this.setState(
      { password: value, isValidPassword: this.validatePassword(value) }
    );
  };
  showHidePassword = () => {
    this.setState({ showHidePassword: !this.state.showHidePassword });
  };

  handleNewConfirmPassword = (value: string) => {
    this.setState({ newConfirmPassword: value }, () => {
      this.setState({ isValidNewConfirmPassword: this.validateConfirmPassword() });
    });
  };
  newShowHideConfirmPassword = () => {
    this.setState(
      { newShowHideConfirmPassword: !this.state.newShowHideConfirmPassword }
    );
  };
  validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };
  validateConfirmPassword = () => {
    if (this.state.newConfirmPassword == this.state.password) {
      return true;
    }
    return false;
  };
  handleModalClose = () =>{
    this.setState({modalVisible:!this.state.modalVisible})
  }
  handleSetNewPasswordApi = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data = {
      token:this.props.navigation.state.params.token,
      new_password: this.state.password,
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setNewPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setNewPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleConfirm = () => {
    this.setState({modalVisible:true},() =>{
      if (
        this.state.password &&
        this.state.newConfirmPassword &&
        this.state.isValidPassword &&
        this.state.isValidNewConfirmPassword
      ) {
        this.handleSetNewPasswordApi();
  
      } else {
        this.setState({modalVisible:false}, () =>{
          setTimeout(() =>{
            this.showAlert(configJSON.errorTitle, configJSON.pValidationMsg);
          },10)
        })
      }
    })
  };
  // Customizable Area End
}
