import React from "react";

// Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
  StatusBar
} from "react-native";
import CustomLoader from "../../../components/src/CustomLoader";
import { Formik } from "formik";
import { configJSON } from "./ForgotPasswordController";
import Arrowleft from 'react-native-vector-icons/Feather';
import { verticalScale, heightPercentageToDP, widthPercentageToDP } from "../../../components/src/Scale";
import CustomInput from "../../../components/src/CommonInput";
import * as Yup from "yup";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          {/* Customizable Area Start */}
          <StatusBar barStyle="dark-content" backgroundColor={this.state.modalVisible?"rgba(0,0,0,0.2)":"#ffffff"} />
              <View style={styles.headerContainer}>
                <TouchableOpacity style={styles.backButton}
                 onPress={() => {
                  this.props.navigation.goBack();
                }}
                >
                  <Arrowleft
                    name="arrow-left"
                    size={verticalScale(28)}
                    color={"#4166f8"}
                   
                  />
                </TouchableOpacity>

              </View>
              <Text style={styles.otpTitle}>Create Password</Text>
              <Text style={styles.otpDes}>Enter your new password to reset your password.</Text>
              <View style = {styles.inputContainer}>

           
              <CustomInput 
              testID="newPassword"
              placeHolder={configJSON.placeHolderPassword} 
              value={this.state.password} 
              handleInput={this.handlePassword} 
              icon={true} 
              showHidePassword={this.showHidePassword} 
              iconName={this.state.showHidePassword ? "eye-off" : "eye"} 
              errorMsg={!this.state.isValidPassword && configJSON.pErrorMsg} 
              passwordVisibility={this.state.showHidePassword} 
              valid = {this.state.isValidPassword}
              />
               <CustomInput testID="confirmPassword" 
            placeHolder={configJSON.placeHolderConfirmPassword2} 
            value={this.state.newConfirmPassword} 
            handleInput={this.handleNewConfirmPassword} 
            icon={true} showHidePassword={this.newShowHideConfirmPassword} 
            iconName={this.state.newShowHideConfirmPassword ? "eye-off" : "eye"} 
            passwordVisibility={this.state.newShowHideConfirmPassword} 
            valid = {this.state.isValidNewConfirmPassword}/>
            {
              !this.state.isValidNewConfirmPassword && String(this.state.newConfirmPassword).length> 1 &&
              <View style = {{ alignSelf:'center', width:widthPercentageToDP(90)}}>
              <Text style={styles.errorMsg}>{configJSON.confirmPasswordInfo}</Text>
            </View>
            }
                  </View>
            <CustomLoader testID = "loaderComponent" modalVisible = {this.state.modalVisible} modalClose = {this.handleModalClose}/>
                  <TouchableOpacity testID="confirmButton" style={styles.confirmButton} onPress={() => this.handleConfirm()}>
                    <Text style={styles.confirmButtonText}>{configJSON.labelconfirmButton}</Text>
                  </TouchableOpacity>
          {/* Customizable Area End */}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: "center"
  },

  passwordShowHide: {
    alignSelf: "center"
  },

  passwordRulesStyle: {
    padding: 15
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5
  },

  passwordContainerWeb: {
    flexDirection: "row",
    borderBottomWidth: 1,
    backgroundColor: "#00000000",
    borderColor: "#767676",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordInputWeb: {
    flex: 1,
    fontSize: 18,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderColor: "#767676",
    borderRadius: 2
  },
  headerContainer: {
    height: heightPercentageToDP(8),
    width: widthPercentageToDP(90),
    justifyContent: 'flex-start',
    alignSelf: 'center'
  },
  backButton: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(12.5),
    borderRadius: verticalScale(25),
    backgroundColor: '#eceef1',
    justifyContent: 'center',
    alignItems: 'center',

  },
  otpTitle: {
    fontSize: verticalScale(33),
    color: "#212330",
    fontWeight: "400",
    marginTop: verticalScale(25),
    alignSelf:'center'

  },
  otpDes: {
    fontSize: verticalScale(14),
    color: "#3c4557",
    marginTop: verticalScale(20),
    fontWeight: "400",
    textAlign: "center",
  },
  inputContainer:{
    marginTop: verticalScale(100)
  },
  confirmButton: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(90),
    backgroundColor: '#4166f8',
    marginTop: verticalScale(20),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: verticalScale(15),
    alignSelf: 'center',
    marginBottom: verticalScale(30)
  },
  confirmButtonText: {
    fontSize: verticalScale(18),
    color: '#fff',
    fontWeight: '400',
    alignSelf: 'center',

  },
  errorMsg: {
    paddingLeft: verticalScale(5),
    fontSize: verticalScale(10),
    color: "#ff0000",
    fontWeight: "400",
    marginTop: verticalScale(5)
}
});
// Customizable Area End
